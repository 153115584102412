import React from "react";
import BackIcon from "../../assets/backIcon.svg";
import Filter from "../../ui/Filter";
import useFetchData from "../../utils/useFetchData";

function ResultsView({ search, updateSearch }) {
  const { selectedFilters, isLoading } = useFetchData(search);

  return (
    <div className="px-20">
      <nav className="flex items-center mt-10 mb-20">
        <button
          className=" bg-white text-black flex p-3 rounded-3xl mr-10"
          onClick={() => updateSearch("")}
        >
          <img
            className="size-6 mr-[10px]"
            src={BackIcon}
            alt="Flèche retour arrière"
          ></img>{" "}
          <p className="font-[barlow] font-bold">Retour</p>
        </button>
        <h2 className="text-white text-5xl font-[inter]">
          Filtres sélectionnés
        </h2>
      </nav>
      {isLoading && (
        <div className="relative flex justify-center items-center mt-4">
          <div
            class="absolute -top-1 rounded-full animate-spin h-40 w-40"
            id="loading_guy"
          ></div>
        </div>
      )}
      {selectedFilters && (
        <div className="container columns-1 lg:columns-3 gap-8 mx-auto">
          {selectedFilters.map((filter) => {
            return <Filter data={filter} key={filter.filterName} />;
          })}
        </div>
      )}
    </div>
  );
}

export default ResultsView;
