import { useEffect, useState } from "react";
import { clearString } from "./stringChecker";
import mock from "../data/formatted.json";
import { mergeArrays } from "./mergeArray";

const url = process.env.REACT_APP_API_URL;
//const url = "http://localhost:8080";

export default function useFetchData(search) {
  const [selectedFilters, setSelectedFilters] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleFetchFilters = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(`${url}/hdtm`, {
          method: "POST",
          body: JSON.stringify({
            text: `Dans tes fichiers, trouve moi tous les filtres qui concernent : ${search}, et avec le résultat, retourne moi ce JSON complété en passant leur value à "true" : [{ "filterName": "", "checkList": [ { "name": "", "value": } ] }],`,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        const cleanData = clearString(data.body.data[0].content[0].text.value);
        setSelectedFilters(mergeArrays(mock, JSON.parse(cleanData)));
      } catch {
        setError("Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };

    handleFetchFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { selectedFilters, isLoading, error };
}
