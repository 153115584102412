import React from "react";

function Chip({ text, updateSearch }) {
  return (
    <button
      className="bg-[#212745] py-3 px-4 rounded-xl text-left font-[barlow]"
      onClick={() => updateSearch(text)}
    >
      {text}
    </button>
  );
}

export default Chip;
