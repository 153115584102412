import React from "react";
import HDTMLogo from "../assets/logos.svg";

function Header() {
  return (
    <header className="py-5 border-b-2">
      <h2 className="ml-20">
        <img src={HDTMLogo} alt="logo d'HDTM" />
      </h2>
    </header>
  );
}

export default Header;
