import { useState } from "react";
import Header from "./ui/Header";
import ResultsView from "./view/resultsView";
import SearchView from "./view/searchView/index";

function App() {
  const [search, setSearch] = useState();

  return (
    <div className="bg-[#111423] min-h-screen w-screen">
      <Header />
      {search ? (
        <ResultsView search={search} updateSearch={setSearch} />
      ) : (
        <SearchView updateSearch={setSearch} />
      )}
    </div>
  );
}

export default App;

// Complète moi ce JSON : {filters: [{name: "", value: ""}]} Avec les données de tes fichiers, pour me donner les filtres qui concernent tous les postes à responsabilité, en Auvergne, et qui aurait participé au salon Santexpo en 2023
