export function clearString(inputString) {
  const firstBraceIndex = inputString.indexOf("[");
  const lastBraceIndex = inputString.lastIndexOf("]");

  if (
    firstBraceIndex !== -1 &&
    lastBraceIndex !== -1 &&
    firstBraceIndex < lastBraceIndex
  ) {
    return inputString.substring(firstBraceIndex, lastBraceIndex + 1);
  } else {
    return inputString; // No braces found or invalid order, return the original string.
  }
}
