import React from "react";

function Filter({ data }) {
  return (
    <div className="bg-white p-5 rounded-lg w-96 my-3 h-fit break-inside-avoid-column">
      <h3 className="text-2xl mb-8 font-[inter]">{data.filterName}</h3>
      <div className="flex flex-col gap">
        {data.checkList.map((element) => {
          return (
            <div className="flex justify-around">
              <input
                type="checkbox"
                id={element.name}
                name={element.name}
                defaultChecked={element.value}
                className="border-2"
              />
              <label className="w-[70%] font-[barlow]" htmlFor={element.name}>
                {element.name}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Filter;
