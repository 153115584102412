import React from "react";
import Chip from "../../ui/Chip";
import SearchBar from "../../ui/SearchBar";

function SearchView({ updateSearch }) {
  return (
    <div className="max-w-2xl mx-auto text-white">
      <h1 className="w-full text-5xl font-bold text-center mt-48 mb-20 font-[barlow]">
        Que cherchez-vous aujourd'hui ?
      </h1>
      <div className="flex space-x-2 mb-4">
        <Chip
          text="Poste de direction Sud-Ouest de la France"
          updateSearch={updateSearch}
        />
        <Chip
          text="Chargé RH association privée à but non lucratif"
          updateSearch={updateSearch}
        />
        <Chip
          text="Cadre des centrales d'achats de groupe hospitalier"
          updateSearch={updateSearch}
        />
      </div>
      <SearchBar updateSearch={updateSearch} />
    </div>
  );
}

export default SearchView;
