export function mergeArrays(arr1, arr2) {
  // Créer un objet pour stocker les valeurs en cours de fusion
  let mergedObj = {};

  // Fusionner les valeurs de arr1 en premier
  mergeFilters(arr1);
  // Fusionner les valeurs de arr2 en prenant la priorité sur arr1
  mergeFilters(arr2);

  // Fonction pour fusionner les filtres
  function mergeFilters(arr) {
    arr.forEach((filter) => {
      let filterName = filter.filterName;
      let checkList = filter.checkList;

      // Si le filtre n'existe pas encore dans l'objet mergedObj, l'ajouter
      if (!mergedObj[filterName]) {
        mergedObj[filterName] = {};
      }

      // Parcourir la checkList du filtre
      checkList.forEach((item) => {
        let itemName = item.name;
        let itemValue = item.value;

        // Si l'élément n'existe pas encore dans le filtre fusionné, l'ajouter
        if (!mergedObj[filterName][itemName]) {
          mergedObj[filterName][itemName] = false;
        }

        // Donner la priorité aux valeurs "true"
        if (itemValue === true) {
          mergedObj[filterName][itemName] = true;
        }
      });
    });
  }

  // Convertir l'objet fusionné en tableau
  let mergedArray = Object.keys(mergedObj).map((filterName) => {
    return {
      filterName: filterName,
      checkList: Object.keys(mergedObj[filterName]).map((itemName) => {
        return {
          name: itemName,
          value: mergedObj[filterName][itemName],
        };
      }),
    };
  });

  return mergedArray;
}
