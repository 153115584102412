import React, { useState } from "react";
import SearchIcon from "../assets/search.svg";
import DeleteIcon from "../assets/crossIcon.svg";

function SearchBar({ updateSearch }) {
  const [searchFocus, setSearchFocus] = useState(false);
  const [searchText, setSearchText] = useState("");

  return (
    <search
      className={`bg-white rounded-xl p-1 flex justify-between border-[1px] font-[barlow] ${searchFocus && "border-[#56CC8E]"}`}
      onKeyDown={event => {
        if (event.key === "Enter") {
          updateSearch(searchText)
        }
      }}
    >
      <input
        className="mx-4 my-[12px] w-full focus-visible:outline-none text-black"
        type="search"
        placeholder="Rechercher un poste..."
        onFocus={() => setSearchFocus(true)}
        onBlur={() => setSearchFocus(false)}
        value={searchText}
        onChange={(event) => setSearchText(event.target.value)}
      />
      {searchText && !searchFocus && (
        <img
          src={DeleteIcon}
          className="mr-3 cursor-pointer"
          alt="Croix pour supprimer la recherche"
          onClick={() => setSearchText("")}
        />
      )}
      {!searchFocus && (
        <img
          src={SearchIcon}
          className="cursor-pointer"
          alt="Icone de recherche"
          onClick={() => updateSearch(searchText)}
        />
      )}
    </search>
  );
}

export default SearchBar;
